/*global require, requirejs, define */
var require = {
    paths: {
        // Vendor files. Parent libs.
        //LR TODO: consider updating jquery version to 3.3.1 as well as jquery.validate to 1.17 and jquery.validate.unobtrusive
        'jquery': 'Vendor/jquery-2.2.4',
        // NOTE: using jquery.validate v 1.13-1.16 was throwing an error in jquery.validate.unobtrusive (line 133 where
        // $.validator was undefined.
        'jquery-validate': 'Vendor/jquery.validate',
        'jquery-validate-unobtrusive': 'Vendor/jquery.validate.unobtrusive',
        'jquery-unobtrusive-ajax': 'Vendor/jquery.unobtrusive-ajax',
        'underscore': 'Vendor/underscore',
        'swiper': 'Vendor/swiper',
        'alertify': 'Vendor/alertify',
        'isMobile': 'Vendor/isMobile',
        'masonry': 'Vendor/masonry.pkgd',
        'baffle': 'Vendor/baffle.min',
        'localforage': 'Vendor/localForage',
        'blockUI': 'Vendor/jquery.blockUI',

        // Plugin files. These files need vendor files as dependencies.
        'text': 'Plugins/text',
        'async': 'Plugins/async',
        'jquery-easing': 'Plugins/jquery.easing.1.3',
        'jquery-simulate': 'Plugins/jquery.simulate',
        'jquery-deparam': 'Plugins/jquery.deparam',
        'jquery-scrolltofixed': 'Plugins/jquery.scrolltofixed',
        'jquery-rangeslider': 'Plugins/jquery.rangeslider',
        'scrollspy': 'Plugins/jquery.scrollspy',
        'bs-affix': 'Plugins/bootstrap/affix',
        'bs-alert': 'Plugins/bootstrap/alert',
        'bs-button': 'Plugins/bootstrap/button',
        'bs-carousel': 'Plugins/bootstrap/carousel',
        'bs-collapse': 'Plugins/bootstrap/collapse',
        'bs-dropdown': 'Plugins/bootstrap/dropdown',
        'bs-modal': 'Plugins/bootstrap/modal',
        'bs-popover': 'Plugins/bootstrap/popover',
        'bs-scrollspy': 'Plugins/bootstrap/scrollspy',
        'bs-tab': 'Plugins/bootstrap/tab',
        'bs-tooltip': 'Plugins/bootstrap/tooltip',
        'bs-transition': 'Plugins/bootstrap/transition',
        'bs-datepicker': 'Plugins/bootstrap-datepicker/bootstrap-datepicker',
        'bs-datepicker-lang-fr': 'Plugins/bootstrap-datepicker/locales/bootstrap-datepicker.fr',
        'moment': '../../../node_modules/moment/min/moment-with-locales',
        'mustache': '../../../node_modules/mustache/mustache',
        'pubsub': '../../../node_modules/pubsub.js/pubsub',

        // Remote files. These files need to be defined in the Grunt/require.js paths.

        'gmaps': '//maps.googleapis.com/maps/api/js?key=AIzaSyCsyVBP1TEFnJNe6OyPa7oz9V18U4JZAcg&libraries=places',
        'ga': [
            '//www.google-analytics.com/analytics',
            'data:application/javascript,'
        ],
        'recaptcha': '//www.google.com/recaptcha/api.js?',

        //'fb': '//connect.facebook.net/en_US/sdk',
        //'tw': '//platform.twitter.com/widgets',
        //'pin': '//assets.pinterest.com/js/pinit',

        // Modules. These are application modules.
        'helpers': 'Modules/helpers/helpers',
        'events': 'Modules/events/events',
        'forms': 'Modules/helpers/forms',
        'analytics': 'Modules/helpers/analytics',
        'getTimes': 'Modules/getTimes/getTimes',
        'getTimesCarousel': 'Modules/getTimesCarousel/getTimesCarousel',
        'getTimesDropDown': 'Modules/getTimesDropDown/getTimesDropDown',
        'getPartners': 'Modules/getPartners20240408/getPartners',
        'getMembers': 'Modules/getMembers/getMembers',
        'getPlayersForAdminUserDetails': 'Modules/getPlayersForAdminUserDetails20240403/getPlayersForAdminUserDetails',
        'getAdminRestrictionComment': 'Modules/getAdminRestrictionComment/getAdminRestrictionComment',
        'getBookingTimes': 'Modules/getBookingTimes/getBookingTimes',
        'getCourtsCarousel': 'Modules/getCourtsCarousel/getCourtsCarousel',
        'getCourtsAndBookingTimesCarousel': 'Modules/getCourtsAndBookingTimesCarousel/getCourtsAndBookingTimesCarousel',
        'data': 'Modules/data/data',
        'google-maps': 'Modules/google-maps20240421/google-maps',
        'google-maps-data': 'Modules/google-maps20240421/google-maps-data',
        'gSettings': 'Modules/google-maps20240421/google-maps-settings',
        'gAutocomplete': 'Modules/google-maps-autocomplete20231025/google-maps-autocomplete',
        'getUserLocation': 'Modules/getUserLocation/getUserLocation',
        'filter': 'Modules/filter20240129/filter',
        'login': 'Modules/login/login',
        'showHidePassword': 'Modules/login/showHidePassword',
        'contact': 'Modules/contact/contact',
        'editClub': 'Modules/editClub/editClub',
        'adminUserDetails': 'Modules/adminUserDetails20231002/adminUserDetails',
        'cookieConsent': 'Modules/cookieConsent/cookieConsent',
        'favoriteSiteStar': 'Modules/favorites20240317/favoriteSiteStar',
        'favoriteSitesRow': 'Modules/favorites20240317/favoriteSitesRow',
        'searchJoueurCordeurCoach': 'Modules/searchJoueurCordeurCoach20240129/searchJoueurCordeurCoach',
        'searchJoueurCordeurCoachData': 'Modules/searchJoueurCordeurCoach20240129/searchJoueurCordeurCoachData',
        'searchJoueurCordeurCoachFilter': 'Modules/searchJoueurCordeurCoach20240129/searchJoueurCordeurCoachFilter',
        'searchJoueurCordeurCoachAutocomplete': 'Modules/searchJoueurCordeurCoach20240129/searchJoueurCordeurCoach-autocomplete',
        'getSearchPageData': 'Modules/getSearchPageData/getSearchPageData'
    },
    shim: {
        'jquery': {exports: '$'},
        'underscore': {exports: '_'},
        // 'fb': {exports: 'FB'},
        'ga': {exports: 'ga'},
        'gmaps': {
            exports: 'google.maps'
        },
        'jquery-easing': ['jquery'],
        'jquery-validate': ['jquery'],
        'jquery-validate-unobtrusive': ['jquery', 'jquery-validate'],
        'jquery-unobtrusive-ajax': ['jquery'],
        'jquery-simulate': ['jquery'],
        'jquery-deparam': ['jquery'],
        'jquery-scrolltofixed': ['jquery'],
        'scrollspy': ['jquery'],
        'bs-transition': ['jquery'],
        'bs-collapse': ['jquery', 'bs-transition'],
        'bs-tab': ['jquery'],
        'bs-modal': ['jquery'],
        'masonry': ['jquery'],
        'bs-datepicker-lang-fr': [
            'jquery',
            'bs-datepicker',
        ]
    }
};
var attrMain = document.body.attributes['data-main'];

//requirejs main module can be specified through data-main attribute
require.deps = ['common'];

if (attrMain) {
    var jsMain = attrMain.value;
    //remove .js extension if present
    var matches = jsMain.match(/^([\w-]+)\.js$/);
    if (matches) {
        jsMain = matches[1];
    }
    require.deps.push(jsMain);
}

/**
 *    triggers when all dependencies are loaded,
 *    arguments are the sum of the AMD module return values listed in require.deps, which varies from page to page
 */
require.callback = function () {
};

requirejs.config(require);

//Define here scripts to be loaded for all pages

define('common',[
    'jquery',
    'helpers',
    'pubsub',
    'isMobile',
    'alertify',
    // TODO: FED to remove all instances of custom tracking
    // 'analytics',
    'jquery-easing',
    'bs-collapse',
    'login',
    'cookieConsent',
    'showHidePassword'
], function ($, helpers, pubsub, isMobile, alertify) {

    // Configure alertify positioning
    alertify.set('notifier','position', 'top-center');

    // TODO: fix this hacky thing!
    window.pubsub = pubsub;

    var $scrollTop;

    helpers.$document.ready(_init);

    // Add a mobile class to the body.
    function checkMobile() {
        if (isMobile.any) {
            helpers.$body.addClass('mobile');
        } else {
            helpers.$body.addClass('desktop');
        }
    }

    // Method to globally bind '.js-scroll-top' to the top.
    function scrollTop() {
        $scrollTop = $('.js-scroll-top');
        $scrollTop.off('click').on('click', function () {
            helpers.scrollTo(helpers.$body);
        });
    }

    function _init() {
        scrollTop();
        checkMobile();
    }

});

